import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./travelspiritfinance.scss";
import kofferLabel from '../assets/images/labels/finance-label.png';
import financeExact from "../assets/images/finance-exact.png";
import { Link } from "gatsby";

export default () => {
  return (
    <Layout>
      <SEO title="Travelspirit Finance & Accounting" urlParams="travelspiritfinance" />
      <div>
        <div className="finance-travel-page-full finance-back-office-section-first">
          <div className="finance-travel-page-container">
            <div className="finance-back-office-content">
              <h6>Financiele administratie</h6>
              <div className="finance-back-office-wrap">
                <h1>FINANCE & <br />ACCOUNTING</h1>
                <img alt="backoffice-koffer label" src={kofferLabel} />
              </div>
              <p>In de dynamische reisbranche is een efficiënte financiële administratie cruciaal. TravelSpirit biedt krachtige functies voor factureren, inkoopfacturen en cashflowbeheer, en integreert naadloos met boekhoudsystemen. <strong>Stroomlijn je financiële processen</strong> en bereid je organisatie beter voor op de uitdagingen van de reisbranche.</p>
              <ul className="finance-travel-page-container-ul">
                <li className="finance-fullwidth">Boekhoudkoppelingen</li>
                <li className="finance-fullwidth">Crediteurenbeheer</li>
                <li className="finance-fullwidth">Debiteurenbeheer</li>
                <li className="finance-fullwidth">Paymentlinks</li>
                <li className="finance-fullwidth">Reisbureauregeling</li>
                <li className="finance-fullwidth">eFacturen</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-back-office-section-two" id="accounting-links">
          <div className="finance-travel-page-container">
            <div className="finance-all-travel-common-box-main">
              <div className="finance-all-travel-common-box-left finance-exact-box">
                <div><img alt="Finance Exact" src={financeExact} /></div>
              </div>
              <div className="finance-all-travel-common-box-right">
                <h3>Boekhoudkoppelingen</h3>
                <p>Geen dubbele invoer van facturen meer! Met TravelSpirit koppel je eenvoudig met diverse boekhoudsystemen, waaronder de <strong>uitgebreide koppeling</strong> met Exact. Hiermee kun je omzet uitstellen naar de maand van vertrek en calculaties direct naar Exact sturen, zodat de verwachte winst zichtbaar is in de boekhouding.</p>
                <p>Betalingen kunnen ook worden <strong>ingelezen,</strong> zodat medewerkers in TravelSpirit kunnen zien of en hoeveel de klant heeft betaald.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-crm-office-section" id="accounts-credit">
          <div className="finance-travel-page-container">
            <div className="finance-all-travel-common-box-main">
              <div className="finance-all-travel-common-box-left">
                <h3>“Met de heldere weergave van je inkoopdata voorkom je dubbele betalingen.”</h3>
              </div>
              <div className="finance-all-travel-common-box-right">
                <h3>Crediteurenbeheer</h3>
                <p>Betaal nooit meer een crediteur twee keer. Met TravelSpirit krijg je <strong>volledig inzicht</strong> in je inkopen, ontvangen inkoopfacturen en kostprijzen. Onze software helpt je uitgaven te <strong>beheren</strong> en te <strong>controleren,</strong> zodat je eenvoudig kunt zien welke facturen zijn betaald en welke nog openstaan.</p>
                <p>Met de <strong>heldere weergave</strong> van je inkoopdata voorkom je dubbele betalingen en kun je weloverwogen financiële keuzes maken</p>
              </div>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-telephonie-section" id="accounts-debit">
          <div className="finance-travel-page-container">
            <div className="finance-all-travel-common-box-main">
              <div className="finance-all-travel-common-box-left">
                <div>
                  <h3>“Met TravelSpirit verstuur je eenvoudig automatische herinneringen.”</h3>
                </div>
              </div>
              <div className="finance-all-travel-common-box-right">
                <h3>Debiteurenbeheer</h3>
                <p>Meestal betalen klanten op tijd, maar soms is een herinnering nodig. Met TravelSpirit verstuur je eenvoudig <strong>automatische herinneringen</strong> voor alle of geselecteerde openstaande facturen, met slechts een paar klikken. </p>
                <p>Je kunt ook <strong>direct een betaallink</strong> meesturen, zodat klanten het openstaande bedrag eenvoudig kunnen voldoen. Dit vergroot de kans op tijdige betalingen en <strong>bespaart je tijd</strong> bij het opvolgen van facturen.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-email-section" id="payment-links">
          <div className="finance-travel-page-container">
            <div className="finance-all-travel-common-box-main">
              <div className="finance-all-travel-common-box-left">
                <h3>“Dit kan voor de aanbetaling, de restantbetaling of direct het hele bedrag.”</h3>
              </div>
              <div className="finance-all-travel-common-box-right">
                <h3>Paymentlinks</h3>
                <p>Stuur de klant een betaallinkje</p>
                <p>TravelSpirit koppelt met Mollie, Buckaroo en Ingenico. Op deze manier kun je de klant een betaallinkje meesturen met de factuur. Dit kan voor de <strong>aanbetaling,</strong> de <strong>restantbetaling</strong> of direct het <strong>hele bedrag.</strong> Afhankelijk van de services die je bij de PSP hebt geactiveerd, kan de klant dan betalen met b.v. iDeal, Creditcard, Apple Pay of Paypal.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-workflow-section" id="travel-agency">
          <div className="finance-travel-page-container">
            <div className="finance-all-travel-common-box-main">
              <div className="finance-all-travel-common-box-left"></div>
              <div className="finance-all-travel-common-box-right">
                <h3>Reisbureauregeling</h3>
                <p>Administreer de reisbureau regeling goed!</p>
                <p>De belastingdienst kan het niet leuker maken, wel ingewikkelder. Daar is de reisbureauregeling, met BTW over de marge, een voorbeeld van.</p>
                <p>Gelukkig kan TravelSpirit hierbij helpen en ervoor zorgen dat deze regeling <strong>goed geadministreerd</strong> wordt.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-producten-section" id="einvoice">
          <div className="finance-travel-page-container">
            <div className="finance-all-travel-common-box-main">
              <div className="finance-all-travel-common-box-left">
                <h3>“onze software genereert automatisch een e-factuur in UBL2.1 formaat en voegt deze als bijlage toe aan je verzending.”</h3>
              </div>
              <div className="finance-all-travel-common-box-right">
                <h3>eFacturen</h3>
                <p>Veel (overheids)instanties verlangen dat facturen als efactuur worden ingediend, wat soms uitdagend kan zijn. Met TravelSpirit wordt dit proces eenvoudig: onze software genereert <strong>automatisch</strong> een e-factuur in <strong>UBL2.1 formaat</strong> en voegt deze als <strong>bijlage</strong> toe aan je verzending.</p>
                <p>Dit bespaart tijd en vermindert de kans op fouten, zodat je moeiteloos voldoet aan de eisen van verschillende instanties. Of je nu regelmatig facturen verstuurt of grote volumes aan moet, TravelSpirit zorgt ervoor dat het indienen van e-facturen <strong>soepel</strong> verloopt.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="finance-travel-page-full finance-kost-section">
          <div className="finance-travel-page-container">
            <div className="finance-kost-section-content">
              <h2>Wat kost de Finance & Accounting?</h2>
              <p>Voor elke type reisorganisatie bieden wij een passend abonnement inclusief de Finance & Accounting</p>
              <Link key='website-pricing' to={`/pricing`}>
                Bekijk abonnementen <span>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="finance-travel-page-full finance-benieuwd-section">
          <div className="finance-travel-page-container">
            <div className="finance-benieuwd-section-content">
              <h2>Benieuwd wat TravelSpirit voor jou kan betekenen?</h2>
              <p>Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om je verder te helpen.</p>
              <div className="finance-benieuwd-btn">
                    <div className="benieuwd-btn-item">
                        <Link key='finance-demo' to={`/demo`} className="btn btn-primary demo-implement">
                            Demo inplannen
                        </Link>
                        <span className="benieuwd-btn-item-text">Online sessie van 30 minuten</span>
                    </div>
                    <div className="benieuwd-btn-item">
                        <Link key='finance-verkennend' to={`/demo-afspraak`} className="btn btn-primary verkennend-implement">
                            Verkennend gesprek
                        </Link>
                        <span className="benieuwd-btn-item-text">Stel online al jouw vragen</span>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
